<template>
    <div class="dashboard">
        <h4 class="pt-3">
            Dashboard
        </h4>
        <hr>
        <div class="row">
            <div class="col-6">
                <itc-card color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white"><h3>Ventas online</h3></div>
                    <div slot="body">
                        <line-chart
                            :chartdata="chartdata"
                            :options="options">
                        </line-chart>
                    </div>
                </itc-card>
            </div>
            <div class="col-6">
                <itc-card color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white"><h3>Ventas locales</h3></div>
                    <div slot="body">
                        <line-chart
                            :chartdata="chartdata"
                            :options="options">
                        </line-chart>
                    </div>
                </itc-card>
            </div>
        </div>
        <div class="row my-2">
            <div class="col-4">
                <itc-card color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white"><h3>Ventas online</h3></div>
                    <div slot="body">
                        <doughnut-chart
                            :chartdata="chartDatas.clickedProducts.chartdata"
                            :options="chartDatas.clickedProducts.options">
                        </doughnut-chart>
                    </div>
                </itc-card>
            </div>
            <div class="col-4">
                <itc-card color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white"><h3>Productos vendidos</h3></div>
                    <div slot="body">
                        <doughnut-chart
                            :chartdata="chartDatas.soldProducts.chartdata"
                            :options="chartDatas.soldProducts.options">
                        </doughnut-chart>
                    </div>
                </itc-card>
            </div>
            <div class="col-4">
                <itc-card color="#343a40" class="custom-shadow">
                    <div slot="header" class="text-white"><h3>Nuevos usuarios</h3></div>
                    <div slot="body">
                        <bar-chart
                            :chartdata="chartdata"
                            :options="options">
                        </bar-chart>
                    </div>
                </itc-card>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import Card from '@/components/mix/Card'
import LineChart from '@/components/mix/LineChart'
import DoughnutChart from '@/components/mix/DoughnutChart'
import BarChart from '@/components/mix/BarChart'

export default {
    data(){
        return {
            chartDatas: {},
            chartdata: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
                datasets: [
                    {
                        label: 'Ventas',
                        backgroundColor: ['#32CD32', '#d3d3d3', '#0000'],
                        borderColor: '#32CD32',
                        data: [40, 35, 20, 15 ,25 ,26, 78, 12],
                        fill: false
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        }
    },
    async mounted(){
        const resp = await axios.get('/diary-data')
        this.chartDatas = resp.data
    },
    components:{
        'line-chart': LineChart,
        'itc-card': Card,
        'doughnut-chart': DoughnutChart,
        'bar-chart': BarChart
    }
}
</script>

<style lang="scss" scoped>
.small-text{
    font-size: 0.8em;
}
</style>